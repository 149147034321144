// extracted by mini-css-extract-plugin
export var Sblock = "styles-module--Sblock--j7lgS";
export var Sheading = "styles-module--Sheading--G7LGq";
export var ViewSbutton1 = "styles-module--ViewSbutton1--RUJol";
export var btnSDiv = "styles-module--btnSDiv--NAO7q";
export var card = "styles-module--card--1xMIN";
export var card0 = "styles-module--card0--4hEL7";
export var card1 = "styles-module--card1--S5Eqg";
export var card2 = "styles-module--card2--GGLMh";
export var card3 = "styles-module--card3--GPAiN";
export var card4 = "styles-module--card4--NKeAt";
export var cardText = "styles-module--cardText--dxITy";
export var cardd2 = "styles-module--cardd2--WTLSK";
export var carddiv = "styles-module--carddiv--ynOQL";
export var cardpadding = "styles-module--cardpadding--XkrYg";
export var explore = "styles-module--explore--lcP03";
export var mainSblock = "styles-module--mainSblock--RjgLh";
export var map = "styles-module--map--glSqx";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var services = "styles-module--services--9E3R0";
export var svg1 = "styles-module--svg1--zsjCr";
export var svg2 = "styles-module--svg2--gEc5E";
export var technology = "styles-module--technology--F4ezz";