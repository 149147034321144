// extracted by mini-css-extract-plugin
export var C_images11 = "styles-module--C_images11--U4IJ9";
export var C_images12 = "styles-module--C_images12---yLlM";
export var C_images21 = "styles-module--C_images21--brjOK";
export var C_images22 = "styles-module--C_images22--D+6T4";
export var C_images3 = "styles-module--C_images3--CJAhY";
export var C_images41 = "styles-module--C_images41--kFFhF";
export var C_images42 = "styles-module--C_images42--LocSB";
export var ImgContainer1 = "styles-module--ImgContainer1--mXKWG";
export var ImgContainer2 = "styles-module--ImgContainer2--AoMuW";
export var ImgForScale = "styles-module--ImgForScale--utZwM";
export var Viewbutton = "styles-module--Viewbutton--xZq4L";
export var Workgraph = "styles-module--Workgraph--bBCVS";
export var Workus = "styles-module--Workus--nWGvN";
export var btnDiv = "styles-module--btnDiv--Qilhb";
export var cardOuterRow = "styles-module--cardOuterRow--Q5CNv";
export var customProjectImage = "styles-module--customProjectImage--1h2zi";
export var customProjectImage1 = "styles-module--customProjectImage1--Rs076";
export var customProjectImage2 = "styles-module--customProjectImage2--FLvFm";
export var customProjectImage3 = "styles-module--customProjectImage3--mR7Z-";
export var customProjectImage4 = "styles-module--customProjectImage4--AaRTW";
export var customProjectImage5 = "styles-module--customProjectImage5--uaaVo";
export var customProjectImage6 = "styles-module--customProjectImage6--o8+t-";
export var custome_div = "styles-module--custome_div---06Te";
export var four = "styles-module--four--7nyuC";
export var mobileImg = "styles-module--mobileImg--JCimX";
export var one = "styles-module--one--EYcYi";
export var parentdiv = "styles-module--parentdiv--WYUMA";
export var primary = "\"abc\"";
export var project = "styles-module--project--GLpWb";
export var projectrow = "styles-module--projectrow--tzBTZ";
export var quality = "styles-module--quality--c97IL";
export var secondary = "\"dec\"";
export var soft = "styles-module--soft--rfqoi";
export var three = "styles-module--three--cyqJN";
export var two = "styles-module--two--91tuJ";